@font-face {
  font-family: 'aidmics';
  src:  url('fonts/aidmics.eot?8pez6y');
  src:  url('fonts/aidmics.eot?8pez6y#iefix') format('embedded-opentype'),
    url('fonts/aidmics.ttf?8pez6y') format('truetype'),
    url('fonts/aidmics.woff?8pez6y') format('woff'),
    url('fonts/aidmics.svg?8pez6y#aidmics') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="aidmics-"], [class*=" aidmics-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'aidmics' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.aidmics-RH:before {
  content: "\e927";
}
.aidmics-LX:before {
  content: "\e924";
}
.aidmics-normal:before {
  content: "\e900";
}
.aidmics-crown:before {
  content: "\e901";
}
.aidmics-warning:before {
  content: "\e902";
}
.aidmics-arrow-down-b:before {
  content: "\e903";
}
.aidmics-edit:before {
  content: "\e904";
}
.aidmics-load-c:before {
  content: "\e905";
}
.aidmics-md-arrow-dropleft-circle:before {
  content: "\e906";
}
.aidmics-md-cloud-download:before {
  content: "\e907";
}
.aidmics-md-funnel:before {
  content: "\e908";
}
.aidmics-disabled-eye:before {
  content: "\e909";
}
.aidmics-eye:before {
  content: "\e90a";
}
.aidmics-bottle-diluted:before {
  content: "\e90b";
}
.aidmics-bottles:before {
  content: "\e90c";
}
.aidmics-bottle-single:before {
  content: "\e90d";
}
.aidmics-BO:before {
  content: "\e90e";
}
.aidmics-BO-whole:before {
  content: "\e90f";
}
.aidmics-CN:before {
  content: "\e910";
}
.aidmics-CN-whole:before {
  content: "\e911";
}
.aidmics-CA:before {
  content: "\e912";
}
.aidmics-CA-whole:before {
  content: "\e913";
}
.aidmics-CE:before {
  content: "\e914";
}
.aidmics-CE-whole:before {
  content: "\e915";
}
.aidmics-EQ:before {
  content: "\e916";
}
.aidmics-EQ-whole:before {
  content: "\e917";
}
.aidmics-SA:before {
  content: "\e918";
}
.aidmics-MU:before {
  content: "\e919";
}
.aidmics-MU-whole:before {
  content: "\e91a";
}
.aidmics-OV:before {
  content: "\e91b";
}
.aidmics-OV-whole:before {
  content: "\e91c";
}
.aidmics-potions:before {
  content: "\e91d";
}
.aidmics-PO:before {
  content: "\e91e";
}
.aidmics-PO-whole:before {
  content: "\e91f";
}
.aidmics-sperms:before {
  content: "\e920";
}
.aidmics-SW:before {
  content: "\e921";
}
.aidmics-SW-whole-female:before {
  content: "\e922";
}
.aidmics-SW-whole-male:before {
  content: "\e923";
}
.aidmics-arrow_right:before {
  content: "\e925";
}
.aidmics-arrow_left:before {
  content: "\e926";
}
.aidmics-refresh:before {
  content: "\e984";
}
