// Colors
$aidmics-blue      : #001b59;
$aidmics-aqua      : #7bcab3;
$aidmics-light-blue: #d1def2;
$aidmics-yellow    : #ffcb70;
$dark-gray-1       : #222222;
$dark-gray-2       : #333333;
$dark-gray-3       : #444444;
$medium-gray-1     : #666666;
$medium-gray-2     : #999999;
$medium-gray-3     : #cccccc;
$light-gray-1      : #eaeaea;
$light-gray-2      : #eeeeee;
$light-gray-3      : #f3f3f3;
$white             : #ffffff;
$black             : #000000;
$danger            : #ad0000;
$light-red         : #e92929;
$dark-red          : #820000;
$warn              : #E33030;

// Media Queries
$tablet : 768px;
$desktop: 1024px;
$giant  : 1280px;
$break-point-height: 812px;

// Bars
$navbar-height : 80px;
$toolbox-height: 56px;
$footer-height : 124px;

// IOS tab bar safe padding
$tablet-safe-padding: 20px;
$phone-safe-padding: 34px;

// Animation
$instant       : 0s;
$flash         : 0.1s;
$fast          : 0.3s;
$medium        : 0.5s;
$slow          : 0.7s;
$sloth         : 0.9s;

@import "../../assets/scss/variables";

// Media Queries
@mixin phone {
  @media (max-width: #{$tablet - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}
@mixin tablet-and-upper {
  @media (min-width: #{$tablet}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop}) and (max-width: #{$giant - 1px}) {
    @content;
  }
}
@mixin desktop-and-upper {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
@mixin giant {
  @media (min-width: #{$giant}) {
    @content;
  }
}

// adjust UI when device has tab bar
@mixin tab-bar {
  padding-bottom: 10px;
  @include phone {
    @media (min-height: #{$break-point-height}) {
      padding-bottom: $phone-safe-padding;
    }
  }
  @include tablet {
    padding-bottom: $tablet-safe-padding;
  }

  @include desktop-and-upper {
    padding-bottom: unset;
  }
}

// Animations
@mixin fade-in($speed) {
  visibility: visible;
  opacity: 1;
  transition: visibility $instant ease $instant, opacity $speed ease $instant;
}
@mixin fade-out($speed) {
  visibility: hidden;
  opacity: 0;
  transition: visibility $instant ease $speed, opacity $speed ease $instant;
}

// Placeholders
@mixin placeholder($color) {
  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
    opacity:  1;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
    opacity:  1;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color;
  }
}








.data-row {
  position: relative;
  padding: 16px 0px;
  text-align: center;
  &:not(:last-child) {
    border-bottom: 1px solid $medium-gray-3;
  }
  .first-layer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    .info-block {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      align-self: stretch;
      width: 200px;
      padding: 0px 10px;
      border-right: 1px solid $medium-gray-3;
      color: $aidmics-blue;
      .flex-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 100%;
        flex: 1;
      }
      .name,
      .age,
      .edit {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }
      .name {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: start;
        white-space: nowrap;
        margin: unset;
        font-size: 28px;
      }
      .age {
        padding: 8px 0px;
      }
      .edit {
        color: #328060;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .detail-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      padding-right: 10px;
      button.detail {
        display: block;
        width: 100%;
        padding: 11.5px 10px;
        background-color: $aidmics-blue;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        border-radius: 5px;
        text-align: center;
        outline: unset;
        border: unset;
        cursor: pointer;

        & + .detail {
          margin-top: 10px;
        }

        &.disabled {
          background-color: $medium-gray-1;
          cursor: not-allowed;
        }
      }
    }
  }

  .data-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    padding: 0 24px;
    border-right: 1px solid $medium-gray-3;
    margin-right: 16px;
    .date {
      font-size: 12px;
      font-weight: 500;
      color: #888;
    }
  }
  .columns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 4px;
      .title,
      .content {
        padding: 4px 0;
      }

      .title {
        font-size: 12px;
        color: $aidmics-aqua;
      }
      .content {
        font-size: 15px;
      }
    }
  }
  
  .second-layer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 500px;
    overflow: hidden;
    opacity: 1;
    transition: all $medium;
    &.hidden {
      max-height: 0;
      padding: 0;
      opacity: 0;
    }
    .columns{
      justify-content: center;
      flex-wrap: wrap;
      .column {
        width: 100px;
        min-width: calc(100% / 5);
        align-items: center;
        padding-top: 1rem;
        .title {
          color: $dark-gray-3;
        }
        .content {
          color: $medium-gray-2;
        }
      }
    }
  }

  @include phone {
    padding: 16px 10px;
    .first-layer {
      flex-direction: column;
      .info-block,
      .data-block {
        width: 100%;
      }
      .info-block {
        justify-content: space-between;
        border-right: unset;
        padding: unset;
        margin-bottom: 1rem;
        .flex-wrap {
          max-width: 40%;
        }
      }
      .data-block {
        padding: unset;
        border-right: unset;
        margin-right: unset;
      }
      .detail-block {
        flex-direction: row;
        padding: unset;

        button.detail {
          width: auto;
          & + .detail {
            margin-top: unset;
            margin-left: 10px;
          }
        }
      }
    }
    .second-layer {
      min-width: calc(100% / 3);
    }
  }
}
