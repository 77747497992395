// Colors
$aidmics-blue      : #001b59;
$aidmics-aqua      : #7bcab3;
$aidmics-light-blue: #d1def2;
$aidmics-yellow    : #ffcb70;
$dark-gray-1       : #222222;
$dark-gray-2       : #333333;
$dark-gray-3       : #444444;
$medium-gray-1     : #666666;
$medium-gray-2     : #999999;
$medium-gray-3     : #cccccc;
$light-gray-1      : #eaeaea;
$light-gray-2      : #eeeeee;
$light-gray-3      : #f3f3f3;
$white             : #ffffff;
$black             : #000000;
$danger            : #ad0000;
$light-red         : #e92929;
$dark-red          : #820000;
$warn              : #E33030;

// Media Queries
$tablet : 768px;
$desktop: 1024px;
$giant  : 1280px;
$break-point-height: 812px;

// Bars
$navbar-height : 80px;
$toolbox-height: 56px;
$footer-height : 124px;

// IOS tab bar safe padding
$tablet-safe-padding: 20px;
$phone-safe-padding: 34px;

// Animation
$instant       : 0s;
$flash         : 0.1s;
$fast          : 0.3s;
$medium        : 0.5s;
$slow          : 0.7s;
$sloth         : 0.9s;

@import "../../assets/scss/variables";

// Media Queries
@mixin phone {
  @media (max-width: #{$tablet - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}
@mixin tablet-and-upper {
  @media (min-width: #{$tablet}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop}) and (max-width: #{$giant - 1px}) {
    @content;
  }
}
@mixin desktop-and-upper {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
@mixin giant {
  @media (min-width: #{$giant}) {
    @content;
  }
}

// adjust UI when device has tab bar
@mixin tab-bar {
  padding-bottom: 10px;
  @include phone {
    @media (min-height: #{$break-point-height}) {
      padding-bottom: $phone-safe-padding;
    }
  }
  @include tablet {
    padding-bottom: $tablet-safe-padding;
  }

  @include desktop-and-upper {
    padding-bottom: unset;
  }
}

// Animations
@mixin fade-in($speed) {
  visibility: visible;
  opacity: 1;
  transition: visibility $instant ease $instant, opacity $speed ease $instant;
}
@mixin fade-out($speed) {
  visibility: hidden;
  opacity: 0;
  transition: visibility $instant ease $speed, opacity $speed ease $instant;
}

// Placeholders
@mixin placeholder($color) {
  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
    opacity:  1;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
    opacity:  1;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color;
  }
}








.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $navbar-height;
  background-color: $aidmics-blue;
  transition: all $slow;
  z-index: 1000;
  overflow: hidden;

  ul {
    margin: unset;
    padding: unset;
  }

  .navbar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-item {
      display: flex;
      align-items: center;
      padding: 0 10px;

      .mobile-menu-logo {
        display: block;
        margin: 40px auto;
        width: 40%;
      }
    }
  }
}

@media (max-width: $desktop) {
  .navbar-container {
    .navbar-content {
      flex-direction: column;
      margin-bottom: 20px;

      &-item {
        width: 100%;
        justify-content: center;
        .species-nav {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 800;
          text-align: center;
          font-size: 26px;

          .dash {
            width: 24px;
            height: 1px;
            background-color: $aidmics-blue;
            margin: 0 12px;
          }

          .species-label {
            &-name {
              font-size: inherit;
            }
          }
        }
        &.mobile-space-between {
          justify-content: space-between;
        }
        &.mobile-flex-column {
          flex-direction: column;
        }
        &:not(.navbar-topper) {
          @include fade-out($slow);
        }
      }

      .separated-bar {
        display: block;
        width: 40%;
        height: 1px;
        margin: 40px 0;
        background-color: $aidmics-blue;
      }
    }

    &.mobile-menu-show {
      height: 100vh;
      height: calc(var(--vh) * 100);
      background-color: rgba(209,222,242,.95);
      overflow: scroll;

      .navbar-content {
        &-item {
          @include fade-in($slow);
        }
      }
    }
  }
}

@media (max-width: $tablet) {
  .navbar-container {
    .navbar-content {
      &-item {
        .mobile-menu-logo {
          width: 60%;
        }
      }

      .separated-bar {
        width: 60%;
      }
    }
  }
}

@include desktop-and-upper {
  .mobile-only {
    display: none !important;
  }
}