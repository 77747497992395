// Colors
$aidmics-blue      : #001b59;
$aidmics-aqua      : #7bcab3;
$aidmics-light-blue: #d1def2;
$aidmics-yellow    : #ffcb70;
$dark-gray-1       : #222222;
$dark-gray-2       : #333333;
$dark-gray-3       : #444444;
$medium-gray-1     : #666666;
$medium-gray-2     : #999999;
$medium-gray-3     : #cccccc;
$light-gray-1      : #eaeaea;
$light-gray-2      : #eeeeee;
$light-gray-3      : #f3f3f3;
$white             : #ffffff;
$black             : #000000;
$danger            : #ad0000;
$light-red         : #e92929;
$dark-red          : #820000;
$warn              : #E33030;

// Media Queries
$tablet : 768px;
$desktop: 1024px;
$giant  : 1280px;
$break-point-height: 812px;

// Bars
$navbar-height : 80px;
$toolbox-height: 56px;
$footer-height : 124px;

// IOS tab bar safe padding
$tablet-safe-padding: 20px;
$phone-safe-padding: 34px;

// Animation
$instant       : 0s;
$flash         : 0.1s;
$fast          : 0.3s;
$medium        : 0.5s;
$slow          : 0.7s;
$sloth         : 0.9s;

@import "../../../assets/scss/variables";

// Media Queries
@mixin phone {
  @media (max-width: #{$tablet - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}
@mixin tablet-and-upper {
  @media (min-width: #{$tablet}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop}) and (max-width: #{$giant - 1px}) {
    @content;
  }
}
@mixin desktop-and-upper {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
@mixin giant {
  @media (min-width: #{$giant}) {
    @content;
  }
}

// adjust UI when device has tab bar
@mixin tab-bar {
  padding-bottom: 10px;
  @include phone {
    @media (min-height: #{$break-point-height}) {
      padding-bottom: $phone-safe-padding;
    }
  }
  @include tablet {
    padding-bottom: $tablet-safe-padding;
  }

  @include desktop-and-upper {
    padding-bottom: unset;
  }
}

// Animations
@mixin fade-in($speed) {
  visibility: visible;
  opacity: 1;
  transition: visibility $instant ease $instant, opacity $speed ease $instant;
}
@mixin fade-out($speed) {
  visibility: hidden;
  opacity: 0;
  transition: visibility $instant ease $speed, opacity $speed ease $instant;
}

// Placeholders
@mixin placeholder($color) {
  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
    opacity:  1;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
    opacity:  1;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color;
  }
}








.language-collapsible-switch {
  position: relative;
  display: flex;
  gap: 12px;
  list-style: none;
  padding: unset;
  margin: unset;

  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 8px;
    border: 1px solid $aidmics-blue;
    border-radius: 74px;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    font-weight: 500;
    letter-spacing: 0.2px;
    transition-property: opacity, visibility, transform, filter;
    transition-timing-function: ease-in-out;
    transition-duration: 300ms;
    cursor: pointer;

    &.active-lang {
      color: #fff;
      background-color: $aidmics-blue;
      border-color: transparent;
      opacity: 1;
      visibility: visible;
      z-index: 1;
    }

    &.item-1,
    &.item-2 {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }

    &.item-1 {
      transition-delay: 100ms;
    }
    &.item-2 {
      transition-delay: 200ms;
    }

    &.open {
      &.item-0,
      &.item-1,
      &.item-2 {
        opacity: 1;
        visibility: visible;
      }

      &.item-1 {
        transform: translateY(calc(-1 * (100% + 12px)));
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4))
      }
      &.item-2 {
        transform: translateY(calc(-2 * (100% + 12px)));
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4))
      }
    }
  }

  .lang-wrap {
    position: relative;
  }
}

@media (max-width: $desktop) {

  .language-collapsible-switch {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    &-item {
      height: 44px;
      line-height: 28px;
      padding: 8px 16px;
      font-size: 20px;
    }
  }
}
