// Colors
$aidmics-blue      : #001b59;
$aidmics-aqua      : #7bcab3;
$aidmics-light-blue: #d1def2;
$aidmics-yellow    : #ffcb70;
$dark-gray-1       : #222222;
$dark-gray-2       : #333333;
$dark-gray-3       : #444444;
$medium-gray-1     : #666666;
$medium-gray-2     : #999999;
$medium-gray-3     : #cccccc;
$light-gray-1      : #eaeaea;
$light-gray-2      : #eeeeee;
$light-gray-3      : #f3f3f3;
$white             : #ffffff;
$black             : #000000;
$danger            : #ad0000;
$light-red         : #e92929;
$dark-red          : #820000;
$warn              : #E33030;

// Media Queries
$tablet : 768px;
$desktop: 1024px;
$giant  : 1280px;
$break-point-height: 812px;

// Bars
$navbar-height : 80px;
$toolbox-height: 56px;
$footer-height : 124px;

// IOS tab bar safe padding
$tablet-safe-padding: 20px;
$phone-safe-padding: 34px;

// Animation
$instant       : 0s;
$flash         : 0.1s;
$fast          : 0.3s;
$medium        : 0.5s;
$slow          : 0.7s;
$sloth         : 0.9s;

@import "../../assets/scss/variables";

// Media Queries
@mixin phone {
  @media (max-width: #{$tablet - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}
@mixin tablet-and-upper {
  @media (min-width: #{$tablet}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop}) and (max-width: #{$giant - 1px}) {
    @content;
  }
}
@mixin desktop-and-upper {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
@mixin giant {
  @media (min-width: #{$giant}) {
    @content;
  }
}

// adjust UI when device has tab bar
@mixin tab-bar {
  padding-bottom: 10px;
  @include phone {
    @media (min-height: #{$break-point-height}) {
      padding-bottom: $phone-safe-padding;
    }
  }
  @include tablet {
    padding-bottom: $tablet-safe-padding;
  }

  @include desktop-and-upper {
    padding-bottom: unset;
  }
}

// Animations
@mixin fade-in($speed) {
  visibility: visible;
  opacity: 1;
  transition: visibility $instant ease $instant, opacity $speed ease $instant;
}
@mixin fade-out($speed) {
  visibility: hidden;
  opacity: 0;
  transition: visibility $instant ease $speed, opacity $speed ease $instant;
}

// Placeholders
@mixin placeholder($color) {
  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
    opacity:  1;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
    opacity:  1;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color;
  }
}








.positioned-popup {
  position: fixed;
  inset: 0px;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  background-color: #001b5966;
  transform: scale(0);
  transition-property: transform, opacity, visibility, background-color;
  transition-timing-function: ease-in-out;
  transition-duration: 0.6s;
  touch-action: none;
  -ms-touch-action: none;
  z-index: 9999;

  .popup-content {
    position: absolute;
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 0;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transition-property: transform, opacity, visibility, max-width, max-height;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
  }

  .popup-close {
    position: absolute;
    top: -12px;
    right: -12px;
    width: 30px;
    height: 30px;
    padding: 4px;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    background-color: $medium-gray-1;
    transform: translateY(20px);
    text-transform: uppercase;
    transition-property: transform, opacity, visibility;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
    z-index: 1;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 60%;
      height: 2px;
      top: 50%;
      left: 50%;
      background-color: #fff;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    @media (max-width: 743px) {
      top: 16px;
      right: 12px;
    }
  }

  &.visible {
    opacity: 1;
    visibility: visible;
    background-color: #001b59cc;
    transform: scale(1);

    .popup-content {
      max-width: 744px;
      max-height: 80vh;
      max-height: calc(var(--vh) * 80);
      opacity: 1;
      visibility: visible;
      transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
      transition-timing-function: ease-in-out;
      transition-delay: 0.6s, 0.6s, 0.6s, 0s;

      @media (max-width: 996px) {
        max-width: 90vw;
        max-height: 90vh;
        max-height: calc(var(--vh) * 90);
      }

      @media (max-width: 743px) {
        max-width: 100vw;
        max-height: 100vh;
        max-height: calc(var(--vh) * 100);
        border-radius: unset;
      }
    }

    .popup-close {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      transition-delay: 0.6s;
    }
  }
}
