// Colors
$aidmics-blue      : #001b59;
$aidmics-aqua      : #7bcab3;
$aidmics-light-blue: #d1def2;
$aidmics-yellow    : #ffcb70;
$dark-gray-1       : #222222;
$dark-gray-2       : #333333;
$dark-gray-3       : #444444;
$medium-gray-1     : #666666;
$medium-gray-2     : #999999;
$medium-gray-3     : #cccccc;
$light-gray-1      : #eaeaea;
$light-gray-2      : #eeeeee;
$light-gray-3      : #f3f3f3;
$white             : #ffffff;
$black             : #000000;
$danger            : #ad0000;
$light-red         : #e92929;
$dark-red          : #820000;
$warn              : #E33030;

// Media Queries
$tablet : 768px;
$desktop: 1024px;
$giant  : 1280px;
$break-point-height: 812px;

// Bars
$navbar-height : 80px;
$toolbox-height: 56px;
$footer-height : 124px;

// IOS tab bar safe padding
$tablet-safe-padding: 20px;
$phone-safe-padding: 34px;

// Animation
$instant       : 0s;
$flash         : 0.1s;
$fast          : 0.3s;
$medium        : 0.5s;
$slow          : 0.7s;
$sloth         : 0.9s;

@import "../../assets/scss/variables";

// Media Queries
@mixin phone {
  @media (max-width: #{$tablet - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}
@mixin tablet-and-upper {
  @media (min-width: #{$tablet}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop}) and (max-width: #{$giant - 1px}) {
    @content;
  }
}
@mixin desktop-and-upper {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
@mixin giant {
  @media (min-width: #{$giant}) {
    @content;
  }
}

// adjust UI when device has tab bar
@mixin tab-bar {
  padding-bottom: 10px;
  @include phone {
    @media (min-height: #{$break-point-height}) {
      padding-bottom: $phone-safe-padding;
    }
  }
  @include tablet {
    padding-bottom: $tablet-safe-padding;
  }

  @include desktop-and-upper {
    padding-bottom: unset;
  }
}

// Animations
@mixin fade-in($speed) {
  visibility: visible;
  opacity: 1;
  transition: visibility $instant ease $instant, opacity $speed ease $instant;
}
@mixin fade-out($speed) {
  visibility: hidden;
  opacity: 0;
  transition: visibility $instant ease $speed, opacity $speed ease $instant;
}

// Placeholders
@mixin placeholder($color) {
  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
    opacity:  1;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
    opacity:  1;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color;
  }
}








.trend-report {
  @include tab-bar;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .mark-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 17px 30px;

    p {
      margin: unset;
      font-weight: 500;
      font-size: 16px;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 10px;
      outline: 0;
      border: none;
      border-radius: 4px;
      background-color: $aidmics-blue;
      color: #fff;
      cursor: pointer;
      i {
        margin-right: 5px;
      }
    }
    &.sticky {
      top: $navbar-height;
      background-color: $light-gray-3;
    }
  }

  .trend-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 0 20px;
    margin: unset;
    margin-bottom: 10px;
    background-color: #fff;
    border-spacing: unset;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    list-style: none;
    
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-left: 10px;

      &.sticky {
        top: 146px;
        background-color: #fff;
      }
      
      &:first-child {
        padding-top: 16px;
        padding-bottom: 16px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $medium-gray-3;
      }

      .info-block,
      .chart-block {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .info-block {
        width: 166px;
      }

      .chart-block {
        flex: 1;
        min-width: 150px;
      }

      .detail-block {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 156px;
      }

      .flex-column {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        .name {
          width: 136px;
          font-size: 28px;
          line-height: 39.2px;
        }

        button {
          width: 100px;
          padding: 11.5px 25px;
          border: none;
          font-weight: 600;
          color: #fff;
          border-radius: 5px;
          cursor: pointer;

          &.btn-good {
            background-color: $aidmics-blue;
          }
          
          &.btn-warn {
            background-color: $warn;
          }
        }

        .message {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 11px;
          margin-bottom: 0;
          
          [class*=aidmics-] {
            font-size: 15px;
            margin-right: 3px;
          }

          >span {
            line-height: 1;
          }
        }

        .text-red {
          color: $warn
        }
      }
    }
  }

  @include phone {
    font-size: 16px;
    .mark-area {
      padding: 16px 17px;
    }

    .trend-list {
      padding: unset;

      &-item {
        padding: 16px;
        justify-content: space-around;

        .info-block {
          width: 150px;
        }

        .chart-block {
          display: none;
        }

        .detail-block {
          width: 143px;
          justify-content: flex-start;
        }

        .flex-column {
          .name {
            width: 99px;
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
    }
  }
  
  .sticky {
    position: sticky;
    z-index: 1;
  }
}