// Colors
$aidmics-blue      : #001b59;
$aidmics-aqua      : #7bcab3;
$aidmics-light-blue: #d1def2;
$aidmics-yellow    : #ffcb70;
$dark-gray-1       : #222222;
$dark-gray-2       : #333333;
$dark-gray-3       : #444444;
$medium-gray-1     : #666666;
$medium-gray-2     : #999999;
$medium-gray-3     : #cccccc;
$light-gray-1      : #eaeaea;
$light-gray-2      : #eeeeee;
$light-gray-3      : #f3f3f3;
$white             : #ffffff;
$black             : #000000;
$danger            : #ad0000;
$light-red         : #e92929;
$dark-red          : #820000;
$warn              : #E33030;

// Media Queries
$tablet : 768px;
$desktop: 1024px;
$giant  : 1280px;
$break-point-height: 812px;

// Bars
$navbar-height : 80px;
$toolbox-height: 56px;
$footer-height : 124px;

// IOS tab bar safe padding
$tablet-safe-padding: 20px;
$phone-safe-padding: 34px;

// Animation
$instant       : 0s;
$flash         : 0.1s;
$fast          : 0.3s;
$medium        : 0.5s;
$slow          : 0.7s;
$sloth         : 0.9s;

@import "../../../assets/scss/variables";

// Media Queries
@mixin phone {
  @media (max-width: #{$tablet - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}
@mixin tablet-and-upper {
  @media (min-width: #{$tablet}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop}) and (max-width: #{$giant - 1px}) {
    @content;
  }
}
@mixin desktop-and-upper {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
@mixin giant {
  @media (min-width: #{$giant}) {
    @content;
  }
}

// adjust UI when device has tab bar
@mixin tab-bar {
  padding-bottom: 10px;
  @include phone {
    @media (min-height: #{$break-point-height}) {
      padding-bottom: $phone-safe-padding;
    }
  }
  @include tablet {
    padding-bottom: $tablet-safe-padding;
  }

  @include desktop-and-upper {
    padding-bottom: unset;
  }
}

// Animations
@mixin fade-in($speed) {
  visibility: visible;
  opacity: 1;
  transition: visibility $instant ease $instant, opacity $speed ease $instant;
}
@mixin fade-out($speed) {
  visibility: hidden;
  opacity: 0;
  transition: visibility $instant ease $speed, opacity $speed ease $instant;
}

// Placeholders
@mixin placeholder($color) {
  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
    opacity:  1;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
    opacity:  1;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color;
  }
}








@mixin expand($speed) {
  max-height: $toolbox-height * 3;
  @include tablet-and-upper {
    max-height: $toolbox-height;
  }
  visibility: visible;
  opacity: 1;
  transition: visibility $instant ease $instant, opacity $speed ease $instant, max-height $speed ease $instant;
}
@mixin shrink($speed) {
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility $instant ease $speed, opacity $speed ease $instant, max-height $speed ease $instant;
}

.dropdown {
  float: left;
  font-size: 16px;
  .button {
    display: flex;
    align-items: center;
    height: $toolbox-height;
    line-height: $toolbox-height;
    padding: 0 15px;
    cursor: pointer;
    i {
      font-size: 20px;
      margin-right: 7px;
    }
  }
  .menu {
    // TODO: try not to block
    // will need some extra redux state
    // and the problem is we don't know the height
    position: absolute;
    width: 100%;
    left: 0;
    background-color: $dark-gray-2;
    padding: 5px 0;
    overflow: hidden;
    z-index: 800;
    @include expand($medium);
    &.hidden {
      @include shrink($medium);
    }
    ul {
      padding: 0 15px;
      margin: 0;
      li {
        padding: 0 10px;
        margin: 5px;
        color: $light-gray-3;
        line-height: $toolbox-height - 20px;
        display: inline-block;
        cursor: pointer;
        transition: all ease $fast;
        // border: 1px solid transparent;
        &.selected {
          // border: 1px solid $light-gray-3;
          border-radius: 5px;
          background-color: $aidmics-light-blue;
          color: $aidmics-blue;
        }
      }
    }
  }
}