// Colors
$aidmics-blue      : #001b59;
$aidmics-aqua      : #7bcab3;
$aidmics-light-blue: #d1def2;
$aidmics-yellow    : #ffcb70;
$dark-gray-1       : #222222;
$dark-gray-2       : #333333;
$dark-gray-3       : #444444;
$medium-gray-1     : #666666;
$medium-gray-2     : #999999;
$medium-gray-3     : #cccccc;
$light-gray-1      : #eaeaea;
$light-gray-2      : #eeeeee;
$light-gray-3      : #f3f3f3;
$white             : #ffffff;
$black             : #000000;
$danger            : #ad0000;
$light-red         : #e92929;
$dark-red          : #820000;
$warn              : #E33030;

// Media Queries
$tablet : 768px;
$desktop: 1024px;
$giant  : 1280px;
$break-point-height: 812px;

// Bars
$navbar-height : 80px;
$toolbox-height: 56px;
$footer-height : 124px;

// IOS tab bar safe padding
$tablet-safe-padding: 20px;
$phone-safe-padding: 34px;

// Animation
$instant       : 0s;
$flash         : 0.1s;
$fast          : 0.3s;
$medium        : 0.5s;
$slow          : 0.7s;
$sloth         : 0.9s;

@import "../../assets/scss/variables";

// Media Queries
@mixin phone {
  @media (max-width: #{$tablet - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}
@mixin tablet-and-upper {
  @media (min-width: #{$tablet}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop}) and (max-width: #{$giant - 1px}) {
    @content;
  }
}
@mixin desktop-and-upper {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
@mixin giant {
  @media (min-width: #{$giant}) {
    @content;
  }
}

// adjust UI when device has tab bar
@mixin tab-bar {
  padding-bottom: 10px;
  @include phone {
    @media (min-height: #{$break-point-height}) {
      padding-bottom: $phone-safe-padding;
    }
  }
  @include tablet {
    padding-bottom: $tablet-safe-padding;
  }

  @include desktop-and-upper {
    padding-bottom: unset;
  }
}

// Animations
@mixin fade-in($speed) {
  visibility: visible;
  opacity: 1;
  transition: visibility $instant ease $instant, opacity $speed ease $instant;
}
@mixin fade-out($speed) {
  visibility: hidden;
  opacity: 0;
  transition: visibility $instant ease $speed, opacity $speed ease $instant;
}

// Placeholders
@mixin placeholder($color) {
  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
    opacity:  1;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
    opacity:  1;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color;
  }
}








.footer {
  background-color: #fff;
  height: $footer-height;
  display: none;
  align-items: center;
  justify-content: center;
  &.alwaysShow {
    display: flex;
  }
  @include desktop-and-upper {
    display: flex;
  }

  .footer-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.separate-bar {
  width: 1px;
  margin: 0 12px;
  align-self: stretch;
  background-color: $aidmics-blue;
}
