// Colors
$aidmics-blue      : #001b59;
$aidmics-aqua      : #7bcab3;
$aidmics-light-blue: #d1def2;
$aidmics-yellow    : #ffcb70;
$dark-gray-1       : #222222;
$dark-gray-2       : #333333;
$dark-gray-3       : #444444;
$medium-gray-1     : #666666;
$medium-gray-2     : #999999;
$medium-gray-3     : #cccccc;
$light-gray-1      : #eaeaea;
$light-gray-2      : #eeeeee;
$light-gray-3      : #f3f3f3;
$white             : #ffffff;
$black             : #000000;
$danger            : #ad0000;
$light-red         : #e92929;
$dark-red          : #820000;
$warn              : #E33030;

// Media Queries
$tablet : 768px;
$desktop: 1024px;
$giant  : 1280px;
$break-point-height: 812px;

// Bars
$navbar-height : 80px;
$toolbox-height: 56px;
$footer-height : 124px;

// IOS tab bar safe padding
$tablet-safe-padding: 20px;
$phone-safe-padding: 34px;

// Animation
$instant       : 0s;
$flash         : 0.1s;
$fast          : 0.3s;
$medium        : 0.5s;
$slow          : 0.7s;
$sloth         : 0.9s;

@import "../../assets/scss/variables";

// Media Queries
@mixin phone {
  @media (max-width: #{$tablet - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}
@mixin tablet-and-upper {
  @media (min-width: #{$tablet}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop}) and (max-width: #{$giant - 1px}) {
    @content;
  }
}
@mixin desktop-and-upper {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
@mixin giant {
  @media (min-width: #{$giant}) {
    @content;
  }
}

// adjust UI when device has tab bar
@mixin tab-bar {
  padding-bottom: 10px;
  @include phone {
    @media (min-height: #{$break-point-height}) {
      padding-bottom: $phone-safe-padding;
    }
  }
  @include tablet {
    padding-bottom: $tablet-safe-padding;
  }

  @include desktop-and-upper {
    padding-bottom: unset;
  }
}

// Animations
@mixin fade-in($speed) {
  visibility: visible;
  opacity: 1;
  transition: visibility $instant ease $instant, opacity $speed ease $instant;
}
@mixin fade-out($speed) {
  visibility: hidden;
  opacity: 0;
  transition: visibility $instant ease $speed, opacity $speed ease $instant;
}

// Placeholders
@mixin placeholder($color) {
  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
    opacity:  1;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
    opacity:  1;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color;
  }
}








.experiment-list {
  @include tab-bar;
  
  .data-rows {
    padding: 20px 10px;
    @include tablet-and-upper {
      padding: 20px 15px;
    }
  }
}

// popup content theme

.positioned-popup {
  .popup-content {
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
  }
}

.pop-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 24px 24px 12px;
  border-bottom: 1px solid #ccc;

  .pop-title-item {
    display: flex;
    align-items: center;
    width: 60%;
    font-size: 28px;

    > h2 {
      flex: 1;
      padding-right: 10px;
      margin: unset;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: inherit;
    }

    [class^="aidmics-"] {
      margin-right: 10px;
    }
  }

  > span {
    display: block;
    font-size: 15px;
  }
}

.pop-content {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: calc(100% - 79px); // pop-title height
  padding: 10px 24px;
}

.tabs-block {
  width: 188px;
  padding: 24px 0px;
  padding-right: 12px;
  margin-right: 12px;
  border-right: 1px solid #ccc;
  z-index: 1;

  .tabs {
    padding: unset;
    margin: unset;
    list-style: none;
    height: 100%;
    padding: 0 3px;
    overflow-y: auto;

    .tab-item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 16px;
      font-size: 20px;
      font-weight: 500;
      border-radius: 5px;
      cursor: pointer;
      transition-property: color, background-color;
      transition-duration: 0.5s;
      transition-timing-function: ease-in-out;

      &.active,
      &:hover {
        color: #fff;
        background-color: $aidmics-blue;
      }

      & + .tab-item {
        margin-top: 20px;
      }
    }
  }
}

.video-container {
  display: flex;
  flex: 1;
  margin-left: 12px;
  overflow-y: hidden;
  
  .video-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px 0px;
    overflow-y: auto;
  }
  
  .data-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 16px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 700;

    .data-title {
      width: 100%;
      padding: 10px 0;
      border-bottom: 1px solid #ccc;
      text-align: center;

      >h2 {
        color: inherit;
        font-size: 18px;
        font-weight: 700;
      }
    }

    .data-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      list-style: none;
      padding: 10px 0;
      margin-bottom: unset;

      .data-item {
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px;

        .title {
          color:$dark-gray-3;
        }

        .value {
          font-size: 24px;
        }

        .unit {
          margin-left: 5px;
          color: inherit;
          font-size: 12px;
        }
      }
    }
  }

  .video-block {
    display: flex;
    align-items: center;
    justify-content: center;

    .no-video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 500;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}

@media (max-width: 743px) {
  .pop-content {
    flex-direction: column;
    justify-content: flex-start;
    padding: unset;
    height: calc(100% - 90px); // pop-title height
  }

  .pop-title {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 42px 12px 12px;

    .pop-title-item {
      width: 100%;
    }

    >span {
      align-self: unset;
      font-size: 12px;
    }
  }

  .tabs-block {
    width: 100%;
    padding: unset;
    margin: unset;
    padding: 0 12px;
    border-right: unset;
    border-top: 6px solid #001b59cc;
    border-bottom: 6px solid #001b59cc;
    
    .tabs {
      display: block;
      height: auto;
      white-space: nowrap;
      overflow-y: unset;
      overflow-x: auto;
      padding: 8px 0;

      .tab-item {
        display: inline-flex;
        font-size: 12px;
        padding: 8px 11px;

        & + .tab-item {
          margin-top: unset;
          margin-left: 10px;
        }

        &:last-child {
          margin-right: 1px;
        }
      }
    }
  }

  .video-container {
    margin-left: unset;
    max-height: unset;

    .video-content {
      padding: 0 12px;
      overflow-y: hidden;
    }

    .data-block {
      margin-bottom: unset;
      
      .data-list {
        .data-item {
          .value {
            font-size: 16px;
          }
        }
      }
    }
  }
}

// static radio
.static-radio-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;

  .static-radio {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

// modal content transition classes
.fade-enter {
  opacity: 0;
  transform: translateX(-30px);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active {
  opacity: 0;
  transform: translateX(30px);
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 0.5s, transform 0.5s;
}

// override react-slick theme
.slider-tabs {
  padding: 10px 0px;

  .tab-item {
    text-align: center;
    font-weight: 700;
    padding: 0 4px;

    > span {
      display: block;
      border-radius: 5px;
      padding: 6px 0;
      transition-property: color, background-color;
      transition-duration: 0.5s;
      transition-timing-function: ease-in-out;
    }
  }
}

.slick-center {
  .tab-item {
    > span {
      color: #fff;
      background-color: $aidmics-blue;
    }
  }
}

.slider-content {
  flex: 1;
  padding-bottom: 16px;
  overflow-y: auto;
}
