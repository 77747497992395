// Colors
$aidmics-blue      : #001b59;
$aidmics-aqua      : #7bcab3;
$aidmics-light-blue: #d1def2;
$aidmics-yellow    : #ffcb70;
$dark-gray-1       : #222222;
$dark-gray-2       : #333333;
$dark-gray-3       : #444444;
$medium-gray-1     : #666666;
$medium-gray-2     : #999999;
$medium-gray-3     : #cccccc;
$light-gray-1      : #eaeaea;
$light-gray-2      : #eeeeee;
$light-gray-3      : #f3f3f3;
$white             : #ffffff;
$black             : #000000;
$danger            : #ad0000;
$light-red         : #e92929;
$dark-red          : #820000;
$warn              : #E33030;

// Media Queries
$tablet : 768px;
$desktop: 1024px;
$giant  : 1280px;
$break-point-height: 812px;

// Bars
$navbar-height : 80px;
$toolbox-height: 56px;
$footer-height : 124px;

// IOS tab bar safe padding
$tablet-safe-padding: 20px;
$phone-safe-padding: 34px;

// Animation
$instant       : 0s;
$flash         : 0.1s;
$fast          : 0.3s;
$medium        : 0.5s;
$slow          : 0.7s;
$sloth         : 0.9s;

@import "../../assets/scss/variables";

// Media Queries
@mixin phone {
  @media (max-width: #{$tablet - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}
@mixin tablet-and-upper {
  @media (min-width: #{$tablet}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop}) and (max-width: #{$giant - 1px}) {
    @content;
  }
}
@mixin desktop-and-upper {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
@mixin giant {
  @media (min-width: #{$giant}) {
    @content;
  }
}

// adjust UI when device has tab bar
@mixin tab-bar {
  padding-bottom: 10px;
  @include phone {
    @media (min-height: #{$break-point-height}) {
      padding-bottom: $phone-safe-padding;
    }
  }
  @include tablet {
    padding-bottom: $tablet-safe-padding;
  }

  @include desktop-and-upper {
    padding-bottom: unset;
  }
}

// Animations
@mixin fade-in($speed) {
  visibility: visible;
  opacity: 1;
  transition: visibility $instant ease $instant, opacity $speed ease $instant;
}
@mixin fade-out($speed) {
  visibility: hidden;
  opacity: 0;
  transition: visibility $instant ease $speed, opacity $speed ease $instant;
}

// Placeholders
@mixin placeholder($color) {
  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
    opacity:  1;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
    opacity:  1;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color;
  }
}








.semen-order {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.5px;

  .mark-area {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 17px 30px;
    
    p {
      margin: unset;
      font-weight: 500;
      font-size: 16px;
      line-height: 22.4px;
    }
    &.sticky {
      top: $navbar-height;
      background-color: $light-gray-3;
    }
  }
  
  .order-list {
    width: 100%;
    padding: 0 20px;
    padding-bottom: 40px;
    margin: unset;
    background-color: #fff;
    border-spacing: unset;
    font-weight: 500;
    list-style: none;
    
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-top: 32px;
      padding-bottom: 16px;

      &.sticky {
        top: 136px;
        background-color: #fff;
      }

      &.good-highlight {
        background: rgba(45, 156, 219, 0.1);
      }

      &.warn-highlight {
        color: #E33030;
        background: rgba(227, 48, 48, 0.1);
      }

      &:not(:last-child) {
        border-bottom: 1px solid #ccc;
      }

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: unset;
        text-align: center;

        &.days,
        &.quality {
          padding-left: 24px;
          padding-right: 24px;
        }

        &.order,
        &.days {
          width: 171px;
        }

        &.name,
        &.quality {
          width: 130px;
        }

        &.name {
          display: block;
          padding-left: 15.5px;
          padding-right: 15.5px;
        }

        &.order {
          .order-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            [class*=" aidmics-"] {
              font-size: 24px;
              margin-right: 10px;
            }
            &.with-icon {
              justify-content: flex-start;
              padding: 0 0 0 32%;
            }
          }
        }
      }
    }
  }

  .sticky {
    position: sticky;
    z-index: 100;
  }

  @include phone {
    font-size: 12px;
    line-height: 16.8px;
    .order-list {
      padding: unset;

      &-item {
        padding-left: 16px;
        padding-right: 16px;
        &:first-child {
          padding-top: 16px;
          padding-bottom: 16px;
        }

        .item {
          &.order,
          &.days {
            width: 120px;
          }

          &.name,
          &.quality {
            width: 80px;
          }
          &.days {
            padding-left: 2.5px;
            padding-right: 2.5px;
          }
          &.name,
          &.quality {
            padding-left: 9.5px;
            padding-right: 9.5px;
          }

          &.order {
            .order-wrap {
              [class*=" aidmics-"] {
                font-size: 20px;
                margin-right: 5px;
              }
              &.with-icon {
                transform: translateX(-5%);
              }
            }
          }
        }
      }
    }
  }
}