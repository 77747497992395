// Colors
$aidmics-blue      : #001b59;
$aidmics-aqua      : #7bcab3;
$aidmics-light-blue: #d1def2;
$aidmics-yellow    : #ffcb70;
$dark-gray-1       : #222222;
$dark-gray-2       : #333333;
$dark-gray-3       : #444444;
$medium-gray-1     : #666666;
$medium-gray-2     : #999999;
$medium-gray-3     : #cccccc;
$light-gray-1      : #eaeaea;
$light-gray-2      : #eeeeee;
$light-gray-3      : #f3f3f3;
$white             : #ffffff;
$black             : #000000;
$danger            : #ad0000;
$light-red         : #e92929;
$dark-red          : #820000;
$warn              : #E33030;

// Media Queries
$tablet : 768px;
$desktop: 1024px;
$giant  : 1280px;
$break-point-height: 812px;

// Bars
$navbar-height : 80px;
$toolbox-height: 56px;
$footer-height : 124px;

// IOS tab bar safe padding
$tablet-safe-padding: 20px;
$phone-safe-padding: 34px;

// Animation
$instant       : 0s;
$flash         : 0.1s;
$fast          : 0.3s;
$medium        : 0.5s;
$slow          : 0.7s;
$sloth         : 0.9s;

@import "../../assets/scss/variables";

// Media Queries
@mixin phone {
  @media (max-width: #{$tablet - 1px}) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}
@mixin tablet-and-upper {
  @media (min-width: #{$tablet}) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: #{$desktop}) and (max-width: #{$giant - 1px}) {
    @content;
  }
}
@mixin desktop-and-upper {
  @media (min-width: #{$desktop}) {
    @content;
  }
}
@mixin giant {
  @media (min-width: #{$giant}) {
    @content;
  }
}

// adjust UI when device has tab bar
@mixin tab-bar {
  padding-bottom: 10px;
  @include phone {
    @media (min-height: #{$break-point-height}) {
      padding-bottom: $phone-safe-padding;
    }
  }
  @include tablet {
    padding-bottom: $tablet-safe-padding;
  }

  @include desktop-and-upper {
    padding-bottom: unset;
  }
}

// Animations
@mixin fade-in($speed) {
  visibility: visible;
  opacity: 1;
  transition: visibility $instant ease $instant, opacity $speed ease $instant;
}
@mixin fade-out($speed) {
  visibility: hidden;
  opacity: 0;
  transition: visibility $instant ease $speed, opacity $speed ease $instant;
}

// Placeholders
@mixin placeholder($color) {
  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
    opacity:  1;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
    opacity:  1;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color;
  }
}








.trend {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 24px 20px;
  font-weight: 500;
  font-size: 16px;

  .info-area {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;

    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .flex-wrap {
        display: flex;
        flex-direction: column;
        .name {
          width: 193px;
          font-size: 40px;
          line-height: 56px;
        }
        .age {
          margin-top: 4px;
          margin-bottom: unset;
        }
      }
    }
    .mid {
      width: 306px;
      margin: unset;
      .slash {
        margin: 0 5px;
      }
      .parent-breed {
        display: flex;
        align-items: center;
        .breed-name {
          max-width: 90px;
          margin-left: 5px;
        }
      }
      li + li {
        margin-top: 0.5rem;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 49.5px;
    }
  }

  .anchor-area,
  .chart-area {
    display: flex;
    width: 100%;
    margin: unset;
    padding: unset;
    list-style: none;
  }

  .anchor-area {
    align-items: center;
    justify-content: flex-start;
    margin-top: 40px;
    margin-bottom: 20px;
    .anchor {
      text-align: center;
      padding: 0px 10px;
      cursor: pointer;

      p {
        margin: unset;
        padding: 9px 17px;
      }
      
      &.active {
        p{
          color: #fff;
          border-radius: 5px;
          background-color: $aidmics-blue;
        }
      }

      & + .anchor {
        border-left: 1px solid $dark-gray-3;
      }
    }
  }

  .chart-area {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .card {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 24px 16px;
      background-color: #fff;;
      border-radius: 10px;

      .title,
      .chart {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }

      .title {
        border-bottom: 1px solid $medium-gray-3;
        padding-top: 10px;
        padding-bottom: 18px;
        .trend-title {
          flex: 1;
        }

        .advice {
          padding: 0 24px; 
          min-width: 100px;
          text-align: center;
        }
      }

      .chart {
        &-wrap {
          flex: 1;
          min-width: 150px;
        }
      }

      .mark {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: unset;
        min-width: 100px;

        span {
          font-size: 20px;
        }
      }

      & + .card {
        margin-top: 24px;
      }
    }
  }
  
  [class*=aidmics-] {
    font-size: 47px;
  }

  @include phone {
    padding: 24px 0px;
    .info-area {
      flex-direction: column;
      margin-bottom: 24px;
      .left,
      .mid {
        width: 100%;
      }
      .left {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
      }
      .mid {
        margin: 0.5rem 0;
      }
    }
    .chart-area {
      .card {
        padding: 16px 20px;
        .title {
          padding-top: 1.5px;
          padding-bottom: 9.5px;
        }
        .mark {
          flex-direction: row;
          >span {
            font-size: 12px;
          }
          [class*=aidmics-] {
            margin-right: 5px;
            font-size: 20px;
          }
        }
        & + .card {
          margin-top: 20px;
        }
      }
    }
  }
}
